/* Import */
@import url('https://fonts.googleapis.com/css?family=Aleo');

/* Global Settings */

*{
	margin: 0;
	padding: 0;
	
	--textColour: rgb(0,0,0);
	--textColourDim: rgb(50,50,50);
	--textColourInverted: rgb(100,100,100);
	--textBGColourDim: rgba(0,0,0,0.1);
	
	--fadeInDelayBackground: 0s;
	--fadeInTimeBackground: 3s;
	
	--fadeInDelayOverlay: 3s;
	--fadeInTimeOverlay: 3s;
		
	--headerHeight: 120px;
	
	--backgroundMotionTime: 60s;
}

/* links */

a {
	color: var(--textColour);
	text-decoration: none;
	transition-duration: 500ms;
	outline: none;	
}
a:active{
	color: var(--textColourDim);
	text-decoration: none;
	transition-duration: 500ms;
	outline: none;	
}
a:focus{
	color: var(--textColourDim);
	text-decoration: none;
	transition-duration: 500ms;
	outline: none;
}
a:hover{
	color: var(--textColourDim);
	text-decoration: none;
	transition-duration: 500ms;
	outline: none;	
}
a:visited {
	color: var(--textColourDim);
	text-decoration: none;
	transition-duration: 500ms;
	outline: none;	
}

/* App master */

.App {
	text-align: center;
	color: var(--textColourDim);
	display: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255,255,255,1.0);
	animation-delay: var(--fadeInDelayOverlay);
	animation: fadeInThenMoveToRight var(--backgroundMotionTime) 1;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
}


/* Navbar styles */

.App-navbar {
	display: inline;
	position: fixed;
	top: 0;
	margin: 0;
	left: 0;
	width: 100vw;
	height: 64px;
	background-image: linear-gradient(rgba(200,200,200,0), rgba(0,0,0,0));
	animation-delay: var(--fadeInDelayOverlay);
	animation: fadeIn var(--fadeInTimeOverlay) 1;
	z-index: 10;
}

.App-navbarLeftTitle{
	float: left;
	padding-top: 14px;
	padding-left: 7vw;
}

.App-navbarLeftTitle p {
	font-weight: 50;
	font-family: "Roboto", sans-serif;
	font-style: normal; 
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	
}
	
.App-navbarRightLinks{
	float: right;
	top: 0;
	padding-top: 0;
	padding-right: 3vw;
	background-color: rgba(0,0,0,0.0);
}

.App-navbarLinksList {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: rgba(0,0,0,0.0);
}

.App-navbarLinksItem {
	float: right;
	display: block;
	text-decoration: none;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.App-navbarLinksList-menuIcon{
	display: inline;
}
/* Header styles */

.App-header {
	position: fixed;
	display: block;
	top: 60px;
	width: 100vw;
	height: var(--headerHeight);
	z-index: 1;
	
}

.App-header p {
	font-weight: 50;
	font-family: 'Aleo', sans-serif;
	font-style: normal; 
	font-size: 34px;
	-webkit-font-smoothing: antialiased;
}

/* Body styles */

.App-body {
	position: fixed;
	display: block;
	top: var(--headerHeight);
	width: 100vw;
	height: calc(100vh - var(--headerHeight));
	z-index: 1;
	overflow: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.App-body::-webkit-scrollbar {
  display: none;
}

/* Footer styles */

.App-footer {
	display: inline;
	position: fixed;
	bottom: 0;
	left: 0;
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 64px;
	background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.0));
	animation-delay: var(--fadeInDelayOverlay);
	animation: fadeIn var(--fadeInTimeOverlay) 1;
	z-index: 9;
	
}

.App-footerSocialMedia {
	float: center;
	position: fixed;
	padding-top: 0px;
	padding-bottom: 4px;
	bottom: 0;
	left: 50vw;
}

.instagram-tooltip .instagram-tooltiptext {
  visibility: hidden;
  display: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.instagram-tooltip:hover .instagram-tooltiptext {
  visibility: visible;
}
.App-footerSocialMedia a {
	display: block;
	text-decoration: none;	
	color: var(--textColourInverted);
}

/* Gallery styles */

.Gallery{
	box-sizing: border-box;
	padding-left: 10vw;
	padding-right: 10vw;
}

.Gallery-header {
  text-align: center;
  padding: 32px;
}

.Gallery-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.Gallery-column {
  flex: 25%;
  max-width: 25%;
  padding: 0 8px;
}


.Gallery-column img {
  margin-top: 0px;
  padding: 0;
  vertical-align: middle;
  width: 100%;
  min-height: 200px;
  object-fit: cover;  
}

.Gallery-column img:hover {
	opacity: 80%;
	cursor: pointer;
}

.__react_modal_image__modal_container img{
	
	max-width: 1000px;
	width: 30vw;
	height: auto;
	object-fit: contain;
	background-color: rgba(0,0,0,0.0);
	
}

.__react_modal_image__modal_container img:hover{
	opacity: 100;
	cursor: auto;
}

.Gallery-column-image {
  margin-top: 0px;
  padding: 0;
  vertical-align: middle;
  width: 100%;
  height: 200px;
  object-fit: cover;  
}

.Gallery-column-image:hover {
	opacity: 100%;
}

.modal_img{
	max-width: 80%;
	height: auto;
	
}

.modal_img img{
	max-width: 80%;
	height: auto;
	
}

.About-body{
	display: block;
	width: 700px;
	margin-left: calc((100% - 700px)/2);
	margin-right: calc((100% - 700px)/2);
	text-align: justify;
}


/* Responsive layout - makes a two column-layout instead of four columns */
@media (max-width: 1000px) {
	.Gallery-column {
		flex: 50%;
		max-width: 50%;
	}
  
	.Gallery{
		padding-left: 5vw;
		padding-right: 5vw;
		
	}
	
	.About-body{
		width: 600px;
		margin-left: calc((100% - 600px)/2);
		margin-right: calc((100% - 600px)/2);
	}
	
	.__react_modal_image__modal_container img{
		
		width: 50vw;
	}
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 650px) {
	.Gallery-column {
		flex: 100%;
		max-width: 100%;
	}
  
	.Gallery{
		padding-left: 0;
		padding-right: 0;
		
	}
	
	.Gallery-column img {
		height: 300px;
	}
	.App-navbarLeftTitle{
		width: 100vw;
		padding-left: 0;
	}
	
	.App-navbarRightLinks{
		float:left;
		padding-left: calc((100vw - 293px)/2);
		padding-right: 0;
	}

	.App-navbarLinksList {
		float:none;
	}
	
	.App-navbarLinksItem{
		
	}

	.App-navbarLinksList-menuIcon{
		display: none;
	}
	
	.App-header {
		top: 120px;
	}
	
	.App-body {
		top: calc( var(--headerHeight) + 40px );
	}
	
	.About-body{
		width: 90vw;
		margin-left: calc((100vw - 90vw)/2);
		margin-right: calc((100vw - 90vw)/2);
	}
	
	.App{
		
	}
	
	.modal-content {
		width: 100%;
	}
	
	.__react_modal_image__modal_container img{
		
		width: 70vw;
	}
}


@keyframes fadeIn {
  from {opacity:0.0;}
  to {opacity:1.0;}
}

@keyframes fadeInThenMoveToRight {
  0% {background-position: left; opacity:0.0;}
  3% {opacity:1.0;}
  100% {background-position: right;}
}